import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/DashboardView.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: "/sinization",
    name: "sinization",
    component: () => import("@/views/sinization/SinizationHome.vue")
  },
  {
    path: "/searchItem",
    name: "searchItem",
    component: () => import("@/views/sinization/SearchItem.vue")
  },
  {
    path: "/patch",
    name: "patch",
    component: () => import("@/views/patch/PatchIndex.vue")
  },
  {
    path: "/noticeView",
    name: "noticeView",
    component: () => import("@/views/notice/NoticeView.vue")
  },
  {
    path: "/rxClientInfo",
    name: "rxClientInfo",
    component: () => import("@/views/client/ClientInfo")
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
