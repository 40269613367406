const noticeStore = {
    state: {
        notice: null
    },
    mutations: {
        SET_NOTICE: (state, notice) => {
            state.notice = notice;
        }
    }
}

export default noticeStore;