import Vue from 'vue'
import Vuex from 'vuex'
import noticeStore from "@/store/noticeStore";
import getters from "@/store/getters";

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    noticeStore
  },
  getters
})
