<template>
    <el-row>
        <el-col :span="24">
            <div style="font-size: 15px; text-align: center;">
                <el-link href="http://www.7lsy.cn">www.7Lsy.cn</el-link>
                <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                <el-link href="https://beian.miit.gov.cn" target="_blank">桂ICP备19007200号-1</el-link>
                <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                <el-link href="https://beian.mps.gov.cn/#/query/webSearch?code=44011302004487" rel="noreferrer" target="_blank"><img src="../../assets/gwaba.png" width="16px"/> 粤公网安备44011302004487</el-link>
            </div>
        </el-col>
        <el-col :span="24" style="text-align: center;">
            <span class="copyright">Copyright &copy; 2023-2024 离火 All Rights Reserved.</span>
        </el-col>
    </el-row>
</template>

<script>
    export default {
        name: "BottomView"
    }
</script>

<style scoped>

    .copyright {
        color: #303133;
        font-size: 15px;
        /*font-weight: bold;*/
    }
</style>