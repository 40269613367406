<template>
  <div id="app">
    <div class="nav-style">
      <div class="nav-container">
        <div class="nav-left">
          <el-image class="logo" :src="logo" fit="cover" lazy @click="gotoHome"></el-image>
        </div>
        <div class="menu-container">
          <NavBar></NavBar>
        </div>
      </div>
    </div>
    <div class="page-style">
      <div class="main-container">
        <router-view/>
      </div>
      <div class="footer-container">
        <Bottom></Bottom>
      </div>
      <el-backtop target=".page-style"></el-backtop>
    </div>
  </div>
</template>

<script>
  import NavBar from "@/views/layout/NavBar";
  import Bottom from "@/views/layout/Bottom";

  export default {
    components: {
      NavBar,
      Bottom
    },
    data() {
      return {
        logo: require("@/assets/logo1.png")
      }
    },
    methods: {
      gotoHome() {
        this.$router.push({ path: "/" });
      }
    }
  }
</script>

<style>
  body {
    margin: 0;
    padding: 0;
  }
  #app {
    font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  }
  .nav-style {
    position: fixed;
    width: 100%;
    height: 60px;
    border-bottom: solid 1px #e6e6e6;
    box-shadow: 0 2px 6px rgba(0, 0, 0, .12);
    background-color: #FFFFFF;
    z-index: 100;
  }
  .nav-container {
    width: 70%;
    height: 60px;
    padding: 0 15%;
  }
  .nav-left {
    display: inline-block;
    width: 50%;
    float: left;
    height: 60px;
    padding-left: 30px;
    box-sizing: border-box;
  }
  .logo {
    /*width: 48px;*/
    height: 48px;
    margin-top: 6px;
    cursor: pointer;
  }
  .main-container {
    width: 70%;
    min-height: calc(100vh - 155px);
    display: inline-block;
    text-align: left;
  }
  .menu-container {
    display: inline-block;
    width: 50%;
    float: right;
    padding-right: 30px;
    box-sizing: border-box;
  }
  ::-webkit-scrollbar {
    width: 10px;
    height: 15px;
  }
  ::-webkit-scrollbar-thumb{
    border-radius:10px;
    -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,.3);
    background-color:#555;
  }
  .page-style {
    position: relative;
    width: 100%;
    height: 100vh;
    text-align: center;
    overflow-y: scroll;
    padding-top: 80px;
    box-sizing: border-box;
    background-color: #F4F5F4;
    z-index: 1;
  }
  .black-line {
    width: 100%;
    height: 10px;
  }
  .footer-container {
    width: 70%;
    height: 40px;
    padding: 15px 15%;
    background-color: #FFFFFF;
  }
</style>
